h1{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
}
h4{
  color: #fff;
font-family: Inter, sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.dpa-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.dpa-container h4 {
  margin-top: 20px;
  font-weight: bold;
}

.dpa-container ul, .dpa-container ol {
  margin-bottom: 20px;
  padding-left: 20px;
}

.dpa-container li {
  margin-bottom: 10px;
}