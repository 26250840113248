form{
    margin-top: 50px;
    padding: 0 20px;
}
select{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23000000' height='16px' width='16px' version='1.1' id='Layer_1' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath id='XMLID_225_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'/%3E%3C/svg%3E");
    background-position:calc(100% - .5rem) center;
    background-repeat: no-repeat;
  background-size: 10px;
}
.form__title{
    display: flex;
    gap: 16px;
    color: #FFF;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    align-items: center;
    margin-bottom: 22px;
}
.form__title p{
    margin: 0
}
.form__title-line{
    background: #525252;
    height: 1px;
flex: 1}
.formgroup{    font-size: unset;
line-height: normal;}
.form__field{
    display: block;
    text-align: left;

}
.form-label{
    color: #FFF;
font-family: Inter, sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 142.857% */
}
.form-actions{
    margin-top: 16px;
}
.PaymentLinkForm{
    max-width: 440px;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    padding: 0 20px;
}
.PaymentLinkForm .btn{
    width: 100%;
    margin-top: 8px;
}
/* .pf-row{
    display: flex;
}
.pf-row .row{
    display: flex;
    flex-direction: column;
} */

@media(max-width: 550px){
    form{
   width: 100%;
    }
    
}