body{
  position: relative;
  background: #000;
  overflow-x: hidden;
}
body:before{
  content: '';
  position: fixed;
  border-radius: 100%;
  background: rgba(32, 0, 255, 0.40);
  filter: blur(192px);
  width: 1155px;
  height: 1155px;
  z-index: -1;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}
body:after{
  content: '';
  position: fixed;
  border-radius: 100%;
  background: rgba(255, 9, 14, 0.37);
  filter: blur(148px);
  width: 556px;
  height: 556px;
  z-index: -1;
right: 0;
bottom: 0;
transform: translate(40%, 30%);
}

.App {
  text-align: center;
}

.logo__wrapper{
  position: relative;
}
.logo__flex{
  display: flex;
  align-items: center;
}
.logo__flex svg{
  margin: 0 20px
}
.logo__flex .App-logo{
  width: 50px
}
.App-logo {
  height: auto;
  width: 80px;
  pointer-events: none;
}
.Mollie-logo{
  width: 140px
}
.connection-status{
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  margin-top: 111px;
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.logo__circle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translatex(-50%) translateY(-50%);
  z-index: -1;
  border-radius: 100%;
  border: 1px solid #525252;
  animation: circlePulse 2s infinite; 
  max-height: 100vh;
  /* box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white; */
}
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
overflow: hidden;
flex: 1;
}
.is-auth .App-header{
  min-height: calc(100vh - 85px);
}
.App-header__text{
  margin-top: 80px;
}
.App-header__actions{
  margin-top: 137px;
}
.App-link {
  color: #61dafb;
}
.payment-table__container{
  margin-top: 40px
}
.payment__table{

  width: 100%;
  color: #FFF;
font-family: Inter, sans-serif;
font-size: 14px;
font-style: normal;
line-height: 20px; /* 142.857% */

}
.pt__row{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pt__col-bold{
  font-weight: 700;
}
.pt__line{
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.pt__line-colored{
  height: 1px;
  background: #525252;
  margin-top: 22px;
}

.mollie-link {
  font-size: 16px;
}

.payment__actions{
  margin-top: 38px;
}

@keyframes circlePulse {
  0%, 100% {
    filter: brightness(1) blur(1px);
  }
  50% {
    filter: brightness(2) blur(1px);
  }
}

.dpa-container {
  font-size: 0.9em;
  line-height: 1.6;
  max-width: 800px;
  margin: auto;
}
.logout-btn{
  margin-bottom:16px;
}
.ConnectionStatus{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
}

@media(max-width: 768px){
  body:before{
  width: 655px;
  height: 655px;
  z-index: -1;
  bottom: 0;
  left: 0;
  transform: translate(-70%, 40%);
}
body:after{
right: 0;
bottom: 0;
top: 0;
transform: translate(70%, -18%);
}
.App-header{
  justify-content: space-between;
}
.App-header.Payment-header{
  justify-content: center;
}
.logo__wrapper{
  margin-top: 130px
}
.App-header__text{
  margin-top: 0;
}
.App-header__actions{
  margin-top: unset;
  margin-bottom: 60px;
}
.payment-header{
  margin-bottom: 40px;
}
}
@media(max-width: 550px){
  .payment-table__container{
    width: 100%;
  }
  .payment__table{
    max-width: unset;
    padding: 0 20px;
  }
}

