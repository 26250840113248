.dpa__wrapper{
   max-width: 420px;
margin-top: 32px;
    background: #fff;
width: 100%;
    border-radius: 6px;
}
.dpa__content{
    color: #000;
    font-size: 14px;
    text-align: left;
    padding: 20px 16px;
    overflow: auto;
    position: relative;

}

/* .dpa__content::-webkit-scrollbar {
  width: 5px;
}

.dpa__content::-webkit-scrollbar-track {
  background: unset;
}

.dpa__content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0; 
  z-index: 1;
}

.dpa__content::-webkit-scrollbar-thumb:hover {
  background: #555;
} */


.dpa__actions{
    padding: 8px 0;
    position: relative;
    padding: 10px 20px;
}
.gradient__spacer{
content: '';
width: 100%;
height: 100px;
bottom: 0;
position: absolute;
background: linear-gradient(180deg, rgba(34,193,195,0) 0%, rgba(255,255,255,1) 100%);
left: 0;
top: -100px;
pointer-events: none;
}